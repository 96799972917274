var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"yt-breadcrumb-wrapper"},[_c('el-breadcrumb',{staticClass:"yt-breadcrumb"},[_c('el-breadcrumb-item',[_vm._v("数据分析")]),_c('el-breadcrumb-item',[_vm._v("题目分析")])],1)],1),_c('div',{staticStyle:{"padding":"10px"}},[_c('div',{staticClass:"top"},[_c('Input',{staticClass:"search-bar",attrs:{"search":"","placeholder":"题干搜索"},on:{"on-search":_vm.search},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('Table',{attrs:{"height":_vm.th,"data":_vm.questionData,"columns":_vm.questionColumn,"loading":_vm.loading},scopedSlots:_vm._u([{key:"stem",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('Tooltip',{staticStyle:{"width":"100%"},attrs:{"placement":"top","transfer":"","max-width":"100%"}},[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('div',{domProps:{"innerHTML":_vm._s(row.stem)}})]),_c('pre',{staticStyle:{"width":"100%"},domProps:{"innerHTML":_vm._s(row.stem)}})])]}},{key:"diff",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.difficulty[row.difficulty].label))])]}},{key:"type",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(_vm.questionType[row.questionType].label))])]}},{key:"wrong",fn:function(ref){
var row = ref.row;
var index = ref.index;
return [_c('span',[_vm._v(_vm._s(Math.round(row.wrongRate * 100))+"%")])]}}])}),_c('Page',{staticClass:"yt-page",class:'yt-page-' + (_vm.total.toString().length > 1 ? _vm.total.toString().length : 2),attrs:{"page-size-opts":[10, 20, 40, 100],"total":_vm.total,"show-sizer":"","show-elevator":"","show-total":""},on:{"on-change":_vm.changePage,"on-page-size-change":_vm.changeSize}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }